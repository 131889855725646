<template>
    <div class="layout-px-spacing">

        <div class="error500 text-center">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
                        <router-link to="/" class="ml-md-5">
                          <img alt="image-505" src="@/assets/img/navbar/logo.png" class="theme-logo" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="container-fluid error-content">
                <div class="">
                    <h1 class="error-number">500</h1>
                  <p class="mini-text">Uuuups!</p>
                    <p class="error-text">Interní chyba serveru!</p>
                    <a href="/" class="btn mt-5 btn-primary">Zpět</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import '../../assets/sass/errors/style-500.scss';
    export default {
        metaInfo: { title: 'Error 500' },
        mounted() {
        },
        methods: {
        }
    };
</script>
